import React from "react";

const Stat = () => {
  return (
    <div className="receivedcash-page">
      <h1 className="page-title">الاحصائيات</h1>
    </div>
  );
};

export default Stat;

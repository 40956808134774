import React from "react";

const Notfound = () => {
  return (
    <>
      <div className="page404">لا يوجد صفحه بهذا العنوان :( </div>
    </>
  );
};

export default Notfound;
